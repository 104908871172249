<template>
  <Barometer
    :type="type"
    :auction-id="type === 'current' ? carOrAuctionId : undefined"
    :car-id="type === 'item' ? carOrAuctionId : undefined"
  />
</template>

<script setup lang="ts">
import Barometer from '@autobid/ui/components/sections/barometer/Barometer.vue'
import type { AuctionItemProps } from '@autobid/ui/types/components/AuctionItem'
import { getIdFromSlug } from '@autobid/ui/utils/car/getIdFromSlug'

const props = defineProps<{ data: AuctionItemProps }>()

const route = useRoute()
const slug = route.params.slug.toString()
const carOrAuctionId = getIdFromSlug(slug)

const type = route.name.includes('auction-current') ? 'current' : 'item'

provide('auction-item-props', props.data)
</script>
