import type { Ref } from 'vue'
import { computed } from 'vue'
import { getDisplayBids } from '@autobid/ui/utils/car/getDisplayBids'
import { getUsersBids } from '@autobid/ui/utils/car/getUsersBids'
import type { BarometerCar } from '@autobid/ui/types/Car'

export function useDisplayingCarBids(car: Ref<BarometerCar>) {
  const displayBids = computed(() => {
    const filteredUserBids = getUsersBids(car.value.bids)

    return getDisplayBids({
      bidStep: car.value.bidStep,
      bids: filteredUserBids,
      callPrice: car.value.price.start
    })
  })

  return {
    displayBids
  }
}
