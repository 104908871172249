import { APP_NAME_ID_MAP } from '../../constants/APP_NAME_ID_MAP'

const APPS_THAT_OPEN_DETAILS_IN_NEW_PAGE = new Set([
  APP_NAME_ID_MAP.bmwyuc,
  APP_NAME_ID_MAP.bmwgroup
])

export function checkOpenDetailsInNewPage(appId: number) {
  return APPS_THAT_OPEN_DETAILS_IN_NEW_PAGE.has(appId)
}
