<template>
  <CommonDialog :opened="active" @close="$emit('close')">
    <template #title>
      {{ $t('shared-car.free-reservation-modal.title') }}
    </template>
    <template #content>
      <div class="flex w-full max-w-xl flex-col gap-2">
        <p class="text-sm text-gray-500">
          {{ $t('shared-car.free-reservation-modal.description') }}
        </p>
      </div>
    </template>
    <template #buttons>
      <footer class="flex w-full justify-end gap-2">
        <ElementsButton class="flex-1 bg-red" @click="$emit('close')">
          {{ $t('bid-confirmation-modal.cancel') }}
        </ElementsButton>

        <ElementsButton
          class="ml-auto flex-1"
          :disabled="isLoading"
          @click="freeReservation"
        >
          {{ isLoading ? $t('loading') : $t('bid-confirmation-modal.confirm') }}
        </ElementsButton>
      </footer>
    </template>
  </CommonDialog>
</template>

<script setup lang="ts">
import { getError } from '@autobid/strapi-integration/utils/getError'
import { useMutation } from '@tanstack/vue-query'
import { useCustomFetch } from '@autobid/ui/composables/useHttp'

type Props = {
  active: boolean
  carId: number
}

const props = defineProps<Props>()
const emits = defineEmits(['close'])

const { $customFetch } = useCustomFetch()
const push = usePush()
const { t, locale } = useI18n()
const mutationFn = () => {
  return $customFetch('/api/backend', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: {
      headers: {
        'Accept-Language': locale.value
      },
      queryMethod: 'POST',
      queryApi: 'auctions',
      queryUrl: '/api/v1/items/reservationrelease',
      queryBody: {
        itemId: props.carId
      }
    }
  })
}

type Resp = {
  data: {
    message: string
    status: number
    success: boolean
  }
}

const { mutate: freeReservation, isLoading } = useMutation({
  mutationFn,
  onError: (error) => push.error(getError(error).message),
  onSuccess: (resp: Resp) => {
    emits('close')

    push[resp.data.success ? 'success' : 'error'](
      resp.data.message ?? t('unexpected-error')
    )
  }
})
</script>
