import type { BarometerCar } from '@autobid/ui/types/Car'

export function inputBidValidation(node, car: BarometerCar) {
  return bidValidation(node.value, car)
}

export function bidValidation(value: number, car: BarometerCar) {
  if (!car.price.current) {
    return true
  }
  const rest = value - car.price.current
  return rest % getMinimalBidStep(car.bidOptions) === 0
}

export function getMinimalBidStep(bidOptions: number[]) {
  return Math.min(...bidOptions)
}
