<template>
  <CommonDialog
    :opened="isOpen && !!goft"
    :footer-props="{
      class: 'flex items-center justify-between gap-2 flex-col md:flex-row'
    }"
    @close="emit('close')"
  >
    <template #title>{{ goft.headline }}</template>
    <template #content>
      <div class="whitespace-pre-line">{{ goft.text }}</div>
    </template>
    <template #buttons>
      <ElementsButton class="!bg-red !p-1" @click="emit('close')">
        {{ $t('car-goft-modal.cancel') }}
      </ElementsButton>
      <ElementsButton class="!p-1" :disabled="isLoading" @click="confirm">
        {{ isLoading ? $t('loading') : $t('car-goft-modal.confirm') }}
      </ElementsButton>
    </template>
  </CommonDialog>
</template>

<script setup lang="ts">
import { getError } from '@autobid/strapi-integration/utils/getError'
import { useMutation } from '@tanstack/vue-query'
import { GOFT_PAGE_ID } from '@autobid/ui/constants/GOFT_PAGE_ID'
import { useCustomFetch } from '@autobid/ui/composables/useHttp'
import { useCarGoft } from '@autobid/ui/composables/car/useCarGoft'

type Props = {
  carId: number
  isOpen: boolean
}

const emit = defineEmits(['close', 'confirm'])
const props = defineProps<Props>()

const goft = useCarGoft(props.carId)

const { locale } = useI18n()
const { $customFetch } = useCustomFetch()

const mutationFn = () => {
  return $customFetch('/api/backend', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: {
      queryMethod: 'POST',
      queryApi: 'webapi',
      headers: {
        'Accept-Language': locale.value
      },
      queryUrl: `/v1/cars/${props.carId}/splashpageaccept/${GOFT_PAGE_ID}`
    }
  })
}

const push = usePush()
const { mutate: confirm, isLoading } = useMutation(mutationFn, {
  onError: (e) => {
    push.error(getError(e).message)
  },
  onSuccess: () => {
    emit('confirm')
  }
})
</script>
