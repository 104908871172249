<template>
  <div>
    <Head>
      <Meta name="robots" content="nofollow, noindex" />
    </Head>

    <div v-if="finishAuctionUrl" class="fixed inset-0 z-[100] bg-white"></div>
    <ElementsCarNotFound v-else :auction-link="auctionLink" />
  </div>
</template>

<script setup lang="ts">
import { useAuctionPagesLink } from '@autobid/ui/composables/useAuctionPagesLink'
import { useOpenLink } from '@autobid/ui/composables/useOpenLink'

interface Props {
  finishAuctionUrl?: string
}
const props = defineProps<Props>()

const { getLink } = useAuctionPagesLink()
const { slug } = useRoute().params
const { open } = useOpenLink()
const auctionLink = computed(() =>
  getLink({
    page: 'car-list',
    slug
  })
)

const redirectToFinishPage = () => {
  if (!props.finishAuctionUrl) return

  open(props.finishAuctionUrl)
}
redirectToFinishPage()
</script>
