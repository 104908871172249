export const CAR_LABEL_LANG_MAP = {
  bg: 'bg_BG',
  cs: 'cz_CZ',
  de: 'de_DE',
  en: 'en_GB',
  es: 'es_ES',
  et: 'et_EE',
  fr: 'fr_FR',
  el: 'gr_GR',
  hr: 'hr_HR',
  hu: 'hu_HU',
  it: 'it_IT',
  lt: 'lt_LT',
  lv: 'lv_LV',
  nl: 'nl_NL',
  pl: 'pl_PL',
  pt: 'pt_PT',
  ro: 'ro_RO',
  ru: 'ru_RU',
  sk: 'sk_SK',
  sl: 'sl_SI',
  sr: 'sr_RS',
  tr: 'tr_TR'
}
