import type { Bid } from '../../types/Bid'

type Params = {
  bids: Bid[]
  callPrice: number
  bidStep: number
}

export const ENTIRE_DISPLAY_SIZE = 21

export const getDisplayBids = ({ bidStep, bids, callPrice }: Params) => {
  let expectedMiddleAmount = 0
  let from = 0

  const currentBid = bids[0]?.amount
  const amountOfHalfBids = Math.floor(ENTIRE_DISPLAY_SIZE / 2)
  const lowestPriceAtDefaultView = callPrice - amountOfHalfBids * bidStep

  if (!currentBid || currentBid === callPrice) {
    // no bids or last bid is equal to call price
    expectedMiddleAmount = callPrice
  } else if (currentBid > callPrice) {
    // last bid bigger than a call price
    if (currentBid > bidStep * amountOfHalfBids) {
      expectedMiddleAmount = currentBid
    } else {
      expectedMiddleAmount = bidStep * (amountOfHalfBids + 1)
    }
  } else {
    // last bid smaller than a call price
    const lastBids = bids.slice(0, amountOfHalfBids + 1)
    const hasBidInTheViewBeforeDefaultView = bids.find((bid) => {
      const highest = lowestPriceAtDefaultView - bidStep
      const lowest = lowestPriceAtDefaultView - bidStep * ENTIRE_DISPLAY_SIZE

      return bid.amount >= lowest && bid.amount <= highest
    })

    if (hasBidInTheViewBeforeDefaultView) {
      // to get smoothly between these two views instead of "jumping" between them
      expectedMiddleAmount = bids[0].amount
    } else if (currentBid < lowestPriceAtDefaultView) {
      // last bid smaller than the smallest value at the default view
      const lowestFromLastBids = lastBids.reverse()[0].amount
      from = lowestFromLastBids

      const possibleTheSmallestValue =
        currentBid - Math.floor(ENTIRE_DISPLAY_SIZE / 2) * bidStep

      from =
        possibleTheSmallestValue > lowestFromLastBids
          ? possibleTheSmallestValue
          : lowestFromLastBids
    } else {
      // last bid bigger than the smallest value at the default view but still smalle than a call price
      from = lowestPriceAtDefaultView
    }
  }

  // if not set, the from value probably was set previously
  if (expectedMiddleAmount) {
    from = expectedMiddleAmount - Math.floor(ENTIRE_DISPLAY_SIZE / 2) * bidStep
  }

  if (from < 0) {
    from = 0
  }

  const bidsKeyedByAmount = bids.reduce((acc, bid) => {
    acc[bid.amount] = bid

    return acc
  }, {} as Record<number, Bid>)

  const prices = Array(ENTIRE_DISPLAY_SIZE)
    .fill(from)
    .map((price, index) => {
      const amount = price + index * bidStep
      const bid = bidsKeyedByAmount[amount]

      return {
        ...(bid ?? {}),
        user_nickname: bid?.user_nickname ?? '',
        status: bid?.status !== undefined ? bid.status : 1,
        amount
      }
    })

  // from highest to lowest
  return prices.reverse() as Bid[]
}
