<!-- eslint-disable vue/no-v-html -->
<template>
  <div
    class="relative flex w-full flex-col justify-center gap-2 border border-primary p-4 text-primary"
  >
    <div
      class="absolute right-4 top-3 ml-auto h-11 w-11 rounded-full border-2 border-primary"
    >
      <i class="ab-icon ab-icon-hammer h-full"></i>
    </div>
    <div v-if="!isAuthed">
      <i18n-t
        keypath="barometer.bid-action-unauthenticated.text"
        tag="p"
        class="pr-12 font-semibold"
      >
        <template #registerLink>
          <common-link :href="registerUrl" class="underline">
            {{
              $t('barometer.bid-action-unauthenticated.register-button-text')
            }}
          </common-link>
        </template>

        <template #loginLink>
          <a role="button" class="underline" @click="signIn">
            {{ $t('barometer.bid-action-unauthenticated.login-button-text') }}
          </a>
        </template>
      </i18n-t>
    </div>

    <template v-else-if="car.stage !== 'FINISHED'">
      <FormKit
        :id="BID_FORM_KEY_DESKTOP"
        v-slot="{ disabled }"
        type="form"
        :actions="false"
        novalidate
        @submit="
          handleSubmit({
            currentType: 1,
            currentPrice: car.price.current ?? 100,
            currentId: car.id
          })
        "
      >
        <div class="flex items-center gap-2">
          <FormKit
            ref="inputRef"
            prefix-icon="euro"
            :disabled="!isUserPermitted"
            type="number"
            :label="$t('barometer.your-bid')"
            name="bid"
            :step="inputBidStep"
            :min="getMinimalInputPrice(car, inputBidStep)"
            :validation="`number|min:${getMinimalInputPrice(car, inputBidStep)}
                |bidValidation:${inputBidStep},${getMinimalInputPrice(
              car,
              inputBidStep
            )}`"
            :validation-rules="{
              bidValidation: (node) => inputBidValidation(node, car)
            }"
            validation-visibility="submit"
            :validation-messages="{
              min: minPriceValidationMessage,
              bidValidation: bidStepPriceValidationMessage
            }"
          />
          <div class="mt-4 w-full">
            <FormKit
              type="submit"
              :disabled="!isUserPermitted || Boolean(disabled)"
              >{{ $t('barometer.offer') }}</FormKit
            >
          </div>
        </div>
        <div class="-mb-2 mt-0.5 flex min-h-[20px] flex-col">
          <FormKitMessages class="mt-0" :node="inputRef?.node" />
        </div>
      </FormKit>
      <ElementsCarQuickBid :car="car" @handle-bid="handleBid" />
      <div>
        <HeadlessPopover class="relative">
          <ElementsButton
            :as="HeadlessPopoverButton"
            type="block"
            size="small"
            :disabled="!isUserPermitted"
          >
            {{ $t('barometer.hire-bid-agent') }}
          </ElementsButton>

          <transition
            enter-active-class="transition duration-200 ease-out"
            enter-from-class="translate-y-1 opacity-0"
            enter-to-class="translate-y-0 opacity-100"
            leave-active-class="transition duration-150 ease-in"
            leave-from-class="translate-y-0 opacity-100"
            leave-to-class="translate-y-1 opacity-0"
          >
            <HeadlessPopoverPanel
              class="absolute top-0 z-10 w-full bg-white p-4 shadow-md"
            >
              <header class="flex items-center justify-between pb-2">
                <HeadlessPopoverButton
                  class="absolute right-2 top-2 flex text-2xl duration-300 hover:scale-110 hover:text-primary"
                >
                  <span class="sr-only">{{ $t('close') }}</span>
                  <Icon name="ic:outline-close" />
                </HeadlessPopoverButton>
              </header>
              <FormKit
                :id="BID_AGENT_FORM_KEY_DESKTOP"
                v-slot="{ disabled }"
                type="form"
                :actions="false"
                novalidate
                @submit="
                  handleSubmit({
                    currentType: 2,
                    currentPrice: car.price.current ?? 100,
                    currentId: car.id
                  })
                "
              >
                <div class="-mt-10 mb-2 flex items-center gap-2">
                  <div class="relative flex w-full flex-col">
                    <FormKit
                      ref="agentInputRef"
                      prefix-icon="euro"
                      :disabled="!isUserPermitted"
                      type="number"
                      :label="$t('barometer.hire-bid-agent')"
                      name="bid-agent"
                      :step="inputBidStep"
                      :min="getMinimalInputPrice(car, inputBidStep)"
                      :validation="`number|min:${getMinimalInputPrice(
                        car,
                        inputBidStep
                      )}
                        |bidValidation:${inputBidStep},${getMinimalInputPrice(
                        car,
                        inputBidStep
                      )}`"
                      :validation-rules="{
                        bidValidation: (node) => inputBidValidation(node, car)
                      }"
                      validation-visibility="submit"
                      :validation-messages="{
                        min: minPriceValidationMessage,
                        bidValidation: bidStepPriceValidationMessage
                      }"
                    />
                  </div>
                  <div class="-mb-4 w-full">
                    <FormKit
                      type="submit"
                      :disabled="!isUserPermitted || Boolean(disabled)"
                      >{{ $t('barometer.hire') }}</FormKit
                    >
                  </div>
                </div>
                <FormKitMessages :node="agentInputRef?.node" />
              </FormKit>
            </HeadlessPopoverPanel>
          </transition>
        </HeadlessPopover>
      </div>
    </template>

    <b v-else-if="car.state" class="flex flex-col justify-center pt-12 text-xl">
      {{
        $t(`barometer.${car.state.toLowerCase()}-info`, {
          theHighestBidder: usersBids[0]?.user_nickname ?? '',
          buyer: usersBids[0]?.user_nickname ?? ''
        })
      }}
    </b>
  </div>

  <LazyCommonDialogBidConfirmation
    v-if="!isBarometerMobile"
    :car="car"
    :bid="temporaryBid"
    :is-open="isConfirmationOpen"
    :type="type"
    :is-bidding="isBidding"
    @confirm="handleBidConfirmation"
    @close="handleBidCancel"
  />
</template>

<script setup lang="ts">
import type { BarometerCar } from '@autobid/ui/types/Car'
import { useBidAction } from '@autobid/ui/composables/car/useBidAction'
import { useIsPermittedToBid } from '@autobid/ui/composables/car/useIsPermittedToBid'
import { FormKitMessages } from '@formkit/vue'
import {
  BID_AGENT_FORM_KEY_DESKTOP,
  BID_FORM_KEY_DESKTOP
} from '@autobid/ui/constants/BAROMETER_FORMS_IDS'
import { getMinimalInputPrice } from '@autobid/ui/utils/car/getMinimalInputPrice'
import { inputBidValidation } from '@autobid/ui/utils/car/bidValidation'
import { useAutobidAuth } from '@autobid/nuxt-auth/src/composables/useAutobidAuth'
import type { AuctionItemProps } from '@autobid/ui/types/components/AuctionItem'
import { computed } from 'vue'
import { useIsBarometerMobile } from '@autobid/ui/composables/car/useIsBarometerMobile'
import { getUsersBids } from '@autobid/ui/utils/car/getUsersBids'
import { HeadlessPopoverButton } from '#components'

type Props = {
  car: BarometerCar
}

const props = defineProps<Props>()
const { isAuthed } = useAutobidAuth()

const isBarometerMobile = useIsBarometerMobile()
const isUserPermitted = useIsPermittedToBid()

const { registerUrl }: AuctionItemProps = inject('auction-item-props')
const {
  handleBid,
  handleSubmit,
  inputRef,
  temporaryBid,
  isConfirmationOpen,
  handleBidConfirmation,
  handleBidCancel,
  agentInputRef,
  type,
  isBidding,
  minPriceValidationMessage,
  inputBidStep,
  bidStepPriceValidationMessage
} = useBidAction({
  bidOptions: computed(() => props.car.bidOptions)
})
const { signIn } = useAutobidAuth()

const usersBids = computed(() => getUsersBids(props.car.bids))
</script>
