<template>
  <CommonDialog
    :container-props="{ class: 'max-w-sm' }"
    :opened="props.isOpened"
    @close="emits('close')"
  >
    <template #title> {{ $t('barometer.depositor-location') }} </template>
    <template #content>
      <div v-if="supplierLabel" class="flex gap-2">
        <h3>
          {{ `${$t('shared-car.supplier')}:` }}
        </h3>
        <span class="text-sm">{{ supplierLabel }}</span>
      </div>
      <div class="flex gap-2">
        <h3>
          {{ `${$t('shared-car.location')}:` }}
        </h3>
        <div class="flex flex-col">
          <span class="text-sm">{{ itemLocationCode }}</span>
          <span class="text-sm">{{ itemLocationCountryName }}</span>
        </div>
      </div>
    </template>
    <template #buttons>
      <ElementsButton class="ml-auto" @click="emits('close')">{{
        $t('close')
      }}</ElementsButton>
    </template>
  </CommonDialog>
</template>

<script setup lang="ts">
const emits = defineEmits(['close'])

type Props = {
  isOpened: boolean
  supplierLabel: string
  itemLocationCode: string
  itemLocationCountryName: string
}

const props = defineProps<Props>()
</script>
