import { useAutobidAuth } from '@autobid/nuxt-auth/src/composables/useAutobidAuth'
import {
  useSetPricelist,
  usePricelistStore
} from '@autobid/nuxt-pinia-store/store/usePricelistStore'
import { storeToRefs } from 'pinia'
import type { BarometerCar } from '@autobid/ui/types/Car'
import type { AuctionItemProps } from '@autobid/ui/types/components/AuctionItem'

export function useCarBarometerButtons(car: Ref<BarometerCar>) {
  const { locale, t } = useI18n()
  const { isAuthed } = useAutobidAuth()
  const { setPricelist } = useSetPricelist(car.value.id)
  const { state } = storeToRefs(usePricelistStore())
  const { pricelistPopupSlug, neutralAuctionPopupSlug }: AuctionItemProps =
    inject('auction-item-props')

  const expertOpinion = computed(
    () =>
      car.value?.documentGroups?.itemAccidentPredamages
        .previousDamageExpertReport
  )

  const generalPDF = computed(() => {
    const { de, en } =
      car.value?.documentGroups?.itemDocuments?.generalPDF || {}

    return locale.value === 'de' ? de || en : en || de
  })

  const generalPDFBtnData = computed(() =>
    generalPDF.value && isAuthed.value
      ? {
          name: generalPDF.value.label,
          url: generalPDF.value.url,
          target: '_blank'
        }
      : null
  )

  const expertOpinionBtnData = computed(() =>
    expertOpinion.value && isAuthed.value
      ? {
          name: t('barometer.expert-opinion'),
          url: expertOpinion.value,
          target: '_blank'
        }
      : null
  )

  const extraChargeBtn = computed(() => ({
    name: t('barometer.extra-charge'),
    url: `#popup-${
      state.value?.status === 'neutralAuction'
        ? neutralAuctionPopupSlug
        : pricelistPopupSlug
    }`,
    onClick: () => {
      setPricelist()
    },
    onMouseOver: () => {
      setPricelist()
    }
  }))

  const externalUrlBtn = computed(() => {
    if (!isAuthed.value) return null

    const eqs = car.value.equipments
    const deUrl = eqs.eq1163?.value
    const deLabel = eqs.eq1162?.value
    const enUrl = eqs.eq1242?.value
    const enLabel = eqs.eq1241?.value

    if (
      (locale.value === 'de' && deUrl) ||
      (locale.value !== 'de' && deUrl && !enUrl)
    ) {
      return {
        name: deLabel || t('barometer.external-url-default-label'),
        url: deUrl
      }
    }

    if (enUrl) {
      return {
        name: enLabel || t('barometer.external-url-default-label'),
        url: enUrl
      }
    }

    return null
  })

  return {
    extraChargeBtn,
    expertOpinion,
    generalPDF,
    generalPDFBtnData,
    expertOpinionBtnData,
    externalUrlBtn
  }
}
