import { useCustomFetch } from '@autobid/ui/composables/useHttp'
import { useMutation, useQuery, useQueryClient } from '@tanstack/vue-query'
import type { MaybeRef } from 'vue'
import type { UseMutationOptions } from '@tanstack/vue-query'
import { getError } from '@autobid/strapi-integration/utils/getError'
import { useAutobidAuth } from '@autobid/nuxt-auth/src/composables/useAutobidAuth'
import { CAR_LABEL_LANG_MAP } from '@autobid/strapi-integration/constants/CAR_LABEL_LANG_MAP'
import type { WebApiCarLabelResponse } from '../../types/CarOfferSheet'
import { useGetCar } from '../useCar'

export function useSheet(carId: MaybeRef<number>, isOpen: Ref<boolean>) {
  const { locale } = useI18n()
  const { isAuthed } = useAutobidAuth()
  const { $customFetch } = useCustomFetch()

  const queryFn = () => {
    return $customFetch<{ data: { carlabel: Record<string, string> } }>(
      '/api/backend',
      {
        method: 'POST',
        body: {
          queryApi: 'webapi',
          queryUrl: `/v1/carlabel/${unref(carId)}`,
          queryMethod: 'GET',
          queryParams: {
            lang: locale.value
          },
          headers: {
            'Accept-Language': locale.value
          }
        }
      }
    )
  }

  return useQuery({
    queryKey: [`sheet/${unref(carId)}`],
    queryFn,
    enabled: isAuthed.value && process.client && isOpen
  })
}

function useSave(
  model: Ref<Record<string, any>>,
  carId: MaybeRef<number>,
  {
    onSuccess,
    ...options
  }: Omit<
    UseMutationOptions<WebApiCarLabelResponse, unknown, unknown, unknown>,
    'mutationFn'
  > = {}
) {
  const { locale, t } = useI18n()
  const push = usePush()
  const { $customFetch } = useCustomFetch()
  const queryClient = useQueryClient()

  const mutationFn = async () => {
    const { readFileAsDataURL } = await import(
      '@autobid/ui/utils/readFileAsDataURL'
    )
    const unrefModel = unref(model)

    return $customFetch<WebApiCarLabelResponse>('/api/backend', {
      method: 'POST',
      body: {
        queryApi: 'webapi',
        queryUrl: `/v1/carlabel/${unref(carId)}`,
        queryFormData: {
          ...unrefModel,
          logo_selected: unrefModel.logo_selected ? '1' : '0',
          logo: unrefModel.logo?.length
            ? await readFileAsDataURL(unrefModel.logo[0].file)
            : undefined
        },
        queryMethod: 'POST',
        headers: {
          'Accept-Language': locale.value
        }
      }
    })
  }

  const { mutate: save, ...rest } = useMutation({
    mutationFn,
    onSuccess: (...args) => {
      push.success(t('car-offer-form-dialog.saved'))
      onSuccess?.(...args)
      queryClient.invalidateQueries({ queryKey: [`sheet/${unref(carId)}`] })
    },
    ...options
  })

  return { save, ...rest }
}

function usePrint(carId: MaybeRef<number>, userId: MaybeRef<number>) {
  const { locale, t } = useI18n()
  const push = usePush()
  const notification = ref()
  const { getCar } = useGetCar()
  const car = getCar(unref(carId))
  const { $customFetch } = useCustomFetch()

  const mutationFn = () => {
    const queryUrl = `/PrintCarLabel?id=${unref(carId)}&lang=${
      CAR_LABEL_LANG_MAP[locale.value]
    }&user_id=${unref(userId)}`

    return $customFetch('/api/backend', {
      method: 'POST',
      body: {
        queryApi: 'carlabel',
        queryMethod: 'GET',
        queryUrl,
        headers: {
          'Accept-Language': locale.value
        }
      }
    })
  }

  const { mutate: print, ...rest } = useMutation({
    mutationFn,
    onMutate: () => {
      notification.value = push.promise(
        t('car-offer-sheet-dialog.pdf-is-preparing')
      )
    },
    onSuccess: (res: { base64: string }) => {
      const downloadLink = document.createElement('a')
      const fileName = `${car.name}-${new Date().toLocaleString()}.pdf`
      downloadLink.href = res.base64
      downloadLink.download = fileName
      downloadLink.click()
      notification.value.resolve(t('car-offer-sheet-dialog.pdf-is-created'))
    },
    onError: (err) => {
      notification.value.reject(getError(err).message)
    }
  })
  return { print, ...rest }
}

export function useCarOfferSheet(
  carId: MaybeRef<number>,
  isOpen: Ref<boolean>
) {
  const { t } = useI18n()
  const previewImage = ref(null)
  const { data: sessionData } = useAuth()
  const model = ref({})
  const isChanged = ref(false)
  const { save } = useSave(model, carId, {
    onSuccess: () => {
      isChanged.value = false
    }
  })
  const userId = computed(() => sessionData?.value?.user?.id)
  const { print } = usePrint(carId, userId)
  const { data: sheet } = useSheet(carId, isOpen)

  const onInit = () => {
    if (!sheet) {
      return
    }
    const filtered = Object.fromEntries(
      Object.entries(sheet?.value?.data?.carlabel ?? {}).filter(
        ([_, value]) => {
          return Boolean(value)
        }
      )
    )
    model.value = filtered
  }

  watch(sheet, onInit)
  onMounted(onInit)

  const schema = {
    user: [
      {
        'floating-label': true,
        name: 'company_name',
        label: t('car-offer-form-dialog.company-name'),
        $formkit: 'text',
        defaultValue: 'profile:extendedData.addressBook.companyName'
      },
      {
        'floating-label': true,
        name: 'company_adress',
        label: t('car-offer-form-dialog.street'),
        $formkit: 'text',
        defaultValue: 'profile:extendedData.addressBook.address'
      },
      {
        'floating-label': true,
        name: 'company_city',
        label: t('car-offer-form-dialog.location'),
        $formkit: 'text',
        validation: 'required',
        defaultValue: 'profile:extendedData.addressBook.city'
      },
      {
        'floating-label': true,
        name: 'company_zip',
        label: t('post-code'),
        $formkit: 'text',
        defaultValue: 'profile:extendedData.addressBook.zip'
      },
      {
        'floating-label': true,
        name: 'tel',
        label: t('car-offer-form-dialog.telephone'),
        $formkit: 'text',
        defaultValue: 'profile:extendedData.addressBook.phone.primary'
      },
      {
        'floating-label': true,
        name: 'fax',
        label: t('car-offer-form-dialog.fax'),
        $formkit: 'text'
      },
      {
        'floating-label': true,
        name: 'email',
        label: t('car-offer-form-dialog.e-mail'),
        $formkit: 'text',
        defaultValue: 'profile:extendedData.addressBook.email.to'
      },
      {
        name: 'logo',
        label: t('car-offer-form-dialog.upload-logo'),
        $formkit: 'file',
        accept: 'image/*'
      },
      {
        'floating-label': true,
        name: 'logo_selected',
        label: t('car-offer-form-dialog.make-visible-in-pdf'),
        $formkit: 'checkbox'
      },
      {
        $el: 'div',
        children: `$slots.default`,
        attrs: {
          class: 'py-4 flex flex-row'
        }
      },
      {
        $formkit: 'submit',
        children: t('save'),
        id: 'save'
      }
    ],
    car: [
      {
        'floating-label': true,
        name: 'inter_number',
        label: t('car-offer-form-dialog.reference-number'),
        $formkit: 'text'
      },
      {
        'floating-label': true,
        name: 'fuel_consumption_combine',
        label: t('car-offer-form-dialog.fuel-comsumption-combined'),
        $formkit: 'text'
      },
      {
        'floating-label': true,
        name: 'fuel_consumption_city',
        label: t('car-offer-form-dialog.fuel-comsumption-urban'),
        $formkit: 'text'
      },
      {
        'floating-label': true,
        name: 'fuel_consumption_route',
        label: t('car-offer-form-dialog.fuel-comsumption-rural'),
        $formkit: 'text'
      },
      {
        'floating-label': true,
        name: 'co2_emission',
        label: t('car-offer-form-dialog.co2-emition-combined'),
        $formkit: 'text'
      },
      {
        'floating-label': true,
        name: 'price',
        label: t('car-offer-form-dialog.price'),
        $formkit: 'text'
      },
      {
        'floating-label': true,
        name: 'description',
        label: t('car-offer-form-dialog.free-text'),
        $formkit: 'textarea'
      },
      {
        $formkit: 'submit',
        children: t('save'),
        id: 'save'
      }
    ]
  } as const

  function onChange(
    event: Event & { target: { name: string; files: Blob[] } }
  ) {
    isChanged.value = true
    if (event.target.name === 'logo') {
      const file = event.target.files[0]
      if (file) {
        const imageURL = URL.createObjectURL(file)
        previewImage.value = imageURL
      }
    }
  }

  function handleSubmit() {
    // info: empty object just to satisfy the inferred type
    save({})
  }

  return {
    onChange,
    handleSubmit,
    print,
    schema,
    isChanged,
    previewImage,
    model,
    sheet
  }
}
