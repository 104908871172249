import { getError } from '@autobid/strapi-integration/utils/getError'
import { useMutation } from '@tanstack/vue-query'
import { reset } from '@formkit/core'
import {
  BID_AGENT_FORM_KEY_MOBILE,
  BID_AGENT_FORM_KEY_DESKTOP,
  BID_FORM_KEY_DESKTOP,
  BID_FORM_KEY_MOBILE
} from '@autobid/ui/constants/BAROMETER_FORMS_IDS'
import { formatPrice } from '@autobid/ui/utils/formatPrice'
import { computed, type Ref } from 'vue'
import { useCustomFetch } from '../useHttp'
import { useSkipGlobalErrorBoundary } from '../useSkipGlobalErrorBoundary'
import { getMinimalBidStep } from '../../utils/car/bidValidation'

/**
 * 1 - normal bid, 2 - agent bid
 */
type Type = 1 | 2

export type Bid = {
  currentValue: number
  currentPrice: number
  currentType: Type
  currentId: number
}

type SubmitParams = {
  currentType: Type
  currentPrice: number
  currentId: number
  skipConfirmation?: boolean
}

type MutationParams = {
  lang: string
  id: number
  type: Type
  price: number
  tte?: number
  currentPrice?: number
}

type Response = {
  data: {
    code: string
    message: string
    level: 'Success' | 'Error' | 'Warning'
  }
}

const TRANSITION_DELAY = 300

type Options = {
  bidOptions: Ref<number[]>
  disableToasts?:
    | {
        success?: boolean
        error?: boolean
        warning?: boolean
      }
    | boolean
  skipReset?: boolean
}

function checkShowToast(
  type: 'success' | 'error' | 'warning',
  options: Options
) {
  if (options.disableToasts === true) {
    return false
  }
  if (typeof options.disableToasts === 'object') {
    return !options.disableToasts[type]
  }
  return true
}

export const useBidAction = (options: Options) => {
  useSkipGlobalErrorBoundary(true)
  const push = usePush()
  const inputRef = ref<any | null>()
  const agentInputRef = ref<any | null>()
  const temporaryBid = ref<number | null>(null)
  const type = ref<Type>(1)
  const isConfirmationOpen = ref(false)
  const temporaryCurrentPrice = ref(0)
  const id = ref(0)
  const { locale, t } = useI18n()
  const { $customFetch } = useCustomFetch()

  const mutationFn = async ({
    id,
    lang,
    type,
    price,
    tte,
    currentPrice
  }: MutationParams) => {
    const result = await $customFetch<Response>(`/api/backend`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: {
        queryMethod: 'POST',
        queryApi: 'auctions',
        queryUrl: '/api/v1/items/bid',
        headers: {
          'Accept-Language': lang
        },
        queryBody: {
          itemId: id,
          type,
          price,
          tte,
          currentPrice
        }
      }
    })

    return result.data
  }

  const { mutateAsync, isLoading: isBidding } = useMutation({
    mutationFn,
    onError: (error) => {
      const parsedError = getError(error)

      if (parsedError.code === 401) {
        isConfirmationOpen.value = false
        return
      }

      if (checkShowToast('error', options)) {
        push.error(parsedError.message)
      }
    },
    onSuccess: (result) => {
      const resultLevel = result.level.toLowerCase() as Lowercase<
        typeof result.level
      >

      const shouldDisplayToast = checkShowToast(resultLevel, options)
      if (!shouldDisplayToast) {
        return
      }

      push[resultLevel](result.message)
    }
  })

  const handleSubmit = ({
    currentType,
    currentPrice,
    currentId,
    skipConfirmation
  }: SubmitParams) => {
    const value =
      currentType === 1
        ? inputRef.value?.node.value
        : agentInputRef.value?.node.value

    if (!value) return

    temporaryBid.value = +value
    handleBid({
      currentValue: +value,
      currentPrice,
      currentType,
      currentId
    })
    if (skipConfirmation) {
      handleBidConfirmation()
    }
  }

  const handleBid = ({
    currentId,
    currentValue,
    currentPrice,
    currentType
  }: Bid) => {
    // if temporary bid already exists, it means that user fill the form
    temporaryBid.value = temporaryBid.value ?? currentValue + currentPrice
    isConfirmationOpen.value = true
    type.value = currentType
    temporaryCurrentPrice.value = currentPrice
    id.value = currentId
  }

  const clearAfterTransition = () => {
    setTimeout(() => {
      if (isConfirmationOpen.value) return

      temporaryBid.value = null
      temporaryCurrentPrice.value = 0
      id.value = 0

      if (!options.skipReset) {
        reset(BID_AGENT_FORM_KEY_MOBILE)
        reset(BID_AGENT_FORM_KEY_DESKTOP)
        reset(BID_FORM_KEY_DESKTOP)
        reset(BID_FORM_KEY_MOBILE)
      }
    }, TRANSITION_DELAY)
  }

  const handleBidConfirmation = async () => {
    await mutateAsync({
      price: temporaryBid.value,
      lang: locale.value,
      type: type.value,
      currentPrice: temporaryCurrentPrice.value,
      id: id.value
    })
    isConfirmationOpen.value = false
    clearAfterTransition()
  }

  const handleBidCancel = () => {
    isConfirmationOpen.value = false
    clearAfterTransition()
  }

  const minPriceValidationMessage = ({ name, args }) => {
    return t('barometer.bid-min', {
      field: name,
      minPrice: formatPrice(args[0])
    })
  }
  const bidStepPriceValidationMessage = ({ name, args }) => {
    return t('barometer.bid-validation', {
      field: name,
      bidStep: formatPrice(args[0]),
      minPrice: formatPrice(args[1])
    })
  }

  const inputBidStep = computed(() =>
    getMinimalBidStep(options.bidOptions.value)
  )

  return {
    handleSubmit,
    handleBid,
    handleBidConfirmation,
    inputRef,
    isConfirmationOpen,
    handleBidCancel,
    agentInputRef,
    type,
    isBidding,
    minPriceValidationMessage,
    bidStepPriceValidationMessage,
    inputBidStep,
    temporaryBid: readonly(temporaryBid)
  }
}
