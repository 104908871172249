<template>
  <div class="flex w-full flex-col justify-end">
    <p
      ref="clampedTextRef"
      class="text-sm text-gray-500"
      :class="{
        'line-clamp-3': !isExpanded
      }"
    >
      {{ text }}
    </p>
    <p
      v-if="clampedTextRef"
      ref="fullTextRef"
      class="absolute left-0 top-0 h-max text-sm opacity-0"
      :style="{ width: `${clampedTextRef?.clientWidth ?? 0}px` }"
    >
      {{ text }}
    </p>
    <button
      v-if="isExpanded || fullTextHeight > clampedTextHeight"
      class="ml-auto w-max"
      @click="toggle"
    >
      <Icon
        name="tabler:chevron-down"
        size="32"
        class="transition"
        :class="isExpanded ? 'rotate-180' : ''"
      />
      <span class="sr-only">{{
        isExpanded ? $t('show-more') : $t('show-less')
      }}</span>
    </button>
  </div>
</template>

<script setup lang="ts">
type Props = {
  text: string
}

defineProps<Props>()

const isExpanded = ref(false)
const clampedTextRef = ref<HTMLParagraphElement>()
const fullTextRef = ref<HTMLParagraphElement>()

const toggle = () => (isExpanded.value = !isExpanded.value)

const fullTextHeight = computed(() => fullTextRef.value?.clientHeight ?? 0)
const clampedTextHeight = computed(
  () => clampedTextRef.value?.clientHeight ?? 0
)
</script>
