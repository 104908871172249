import { computed } from 'vue'
import { useCheckUserPermission } from '../useCheckUserPermission'
import { useUserHasBidBlocked } from '../useIsUserHasBidBlocked'

export function useIsPermittedToBid() {
  const { checkUserPermission } = useCheckUserPermission()
  const isBidBlocked = useUserHasBidBlocked()

  return computed(() => {
    return checkUserPermission('Bid') && !isBidBlocked.value
  })
}
