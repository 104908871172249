<template>
  <div class="mt-4 hidden flex-col gap-2 lg:flex">
    <div v-if="isAuthed" class="flex gap-2">
      <div
        v-if="buttonsFirstLevelLeft.length"
        class="flex flex-1 items-center gap-2"
      >
        <ElementsButton
          v-for="item of buttonsFirstLevelLeft"
          :key="item.name"
          v-bind="item"
          type="block"
          class="flex h-10 whitespace-nowrap"
        >
          <span class="text-sm">{{ item.name }}</span>
        </ElementsButton>
      </div>

      <data class="flex-1">
        <ElementsButton class="flex h-10 !w-full" @click="emits('showAllBids')">
          <span class="text-sm">
            {{ $t('barometer.show-all-bids') }}
          </span>
        </ElementsButton>
      </data>

      <div
        v-if="buttonsFirstLevelRight.length"
        class="flex flex-1 items-center gap-2"
      >
        <ElementsButton
          v-for="item of buttonsFirstLevelRight"
          :key="item.name"
          v-bind="item"
          type="block"
          class="flex h-10 whitespace-nowrap"
        >
          <span class="text-sm">{{ item.name }}</span>
        </ElementsButton>
      </div>
    </div>
    <div class="mt-2 flex items-center gap-2">
      <ElementsButton
        v-for="item of buttonsSecondLevel"
        :key="item.name"
        v-bind="item"
        color="accent1"
        class="h-10 flex-1"
        @click="() => (item.onClick ? item.onClick() : undefined)"
      >
        <span class="text-sm">
          {{ item.name }}
        </span>
      </ElementsButton>
    </div>
  </div>

  <DialogSupplier
    v-if="car.supplier"
    :supplier-label="car.supplier.label"
    :is-opened="state.depositorModalShown"
    :item-location-code="car.additionalInformation.itemLocationCode"
    :item-location-country-name="
      car.additionalInformation.itemLocationCountry?.name ?? ''
    "
    @close="state.depositorModalShown = false"
  />
  <CarOfferSheet
    :car-id="car.id"
    :is-open="state.bidSheetShown"
    @close="state.bidSheetShown = false"
  />
</template>

<script setup lang="ts">
import type { BarometerCar } from '@autobid/ui/types/Car'
import { computed, ref } from 'vue'
import { useAutobidAuth } from '@autobid/nuxt-auth/src/composables/useAutobidAuth'
import { useCarBarometerButtons } from '@autobid/ui/composables/car/useCarBarometerButtons'
import DialogSupplier from '@autobid/ui/components/common/dialog/DialogSupplier.vue'
import CarOfferSheet from '@autobid/ui/components/common/dialog/CarOfferSheet.vue'
import { useCheckOpenDetailsInNewPage } from '@autobid/ui/composables/car/useCheckOpenDetailsInNewPage'
import { useAuction } from '@autobid/ui/composables/useAuction'

type Props = {
  car: BarometerCar
  transportUrl: string
  currentUrl: string
  detailsUrl: string
}

const { t } = useI18n()

const props = defineProps<Props>()
const emits = defineEmits(['showAllBids'])
const { auction } = useAuction(props.car.auctionId)

const openDetailsInNewPage = useCheckOpenDetailsInNewPage()

const {
  expertOpinion,
  generalPDF,
  expertOpinionBtnData,
  generalPDFBtnData,
  extraChargeBtn,
  externalUrlBtn
} = useCarBarometerButtons(computed(() => props.car))

const state = ref({
  depositorModalShown: false,
  bidSheetShown: false
})

const { isAuthed } = useAutobidAuth()
const buttonsFirstLevelLeft = computed(() =>
  [
    {
      name: t('barometer.bid-sheet'),
      onClick: () => {
        state.value.bidSheetShown = true
      }
    },
    expertOpinion.value || generalPDF.value
      ? expertOpinion.value
        ? expertOpinionBtnData.value
        : generalPDFBtnData.value
      : null
  ].filter(Boolean)
)

const buttonsFirstLevelRight = computed(() =>
  [
    generalPDF.value && expertOpinion.value ? generalPDFBtnData.value : null,
    externalUrlBtn.value,
    auction.value.stage !== 'FINISHED'
      ? { name: t('barometer.current-auction'), url: props.currentUrl }
      : undefined
  ].filter(Boolean)
)

const buttonsSecondLevel = computed(() =>
  [
    {
      name: t('barometer.details-and-pictures'),
      url: props.detailsUrl,
      target: openDetailsInNewPage ? '_blank' : undefined
    },
    {
      name: t('barometer.depositor-location'),
      onClick: () => {
        state.value.depositorModalShown = true
      }
    },
    props.transportUrl
      ? { name: t('barometer.vehicle-transport'), url: props.transportUrl }
      : null,
    extraChargeBtn.value
  ].filter(Boolean)
)
</script>
