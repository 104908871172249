<template>
  <div class="mx-auto flex w-full flex-col lg:hidden">
    <HeaderMobile
      :sca-popup-slug="scaPopupSlug"
      :car="car"
      :time-remaining="timeRemaining"
      :is-countdown-over="isCountdownOver"
      :title="title"
    />
    <BidMobileInfo :tax-popup-slug="taxPopupSlug" :car="car" />
    <BidActionMobile :car="car" />
    <div
      v-if="car.isHighestBidder && isAuthed"
      class="ml-auto flex w-full justify-between gap-2 px-4 py-2 uppercase"
      :class="{
        [COLORS.darkOrange.text]: state.isUnderAuctionReservation,
        [COLORS.green.text]: state.isSold,
        'text-primaryDark': !state.isUnderAuctionReservation && !state.isSold
      }"
    >
      {{ $t('barometer.you-are-the-highest-bidder') }}
      <button
        class="ml-auto flex h-12 w-12 items-center justify-center rounded-full border-4 border-primary text-lg"
      >
        <Icon name="akar-icons:arrow-up" size="48" class="text-primary" />
      </button>
    </div>

    <ElementsCarParameters class="mt-2 w-full px-2 md:block" :car="car" />

    <CarMobileDescription
      :text="Object.values(car?.ranking ?? {}).join(', ')"
      class="p-2 pt-0"
    />

    <div class="px-2">
      <CurrentAuctionButton
        v-if="
          barometerType === 'item' &&
          auction.stage !== 'FINISHED' &&
          car.stage !== 'IN_AUCTION'
        "
        :slug="auction.slug"
        type="block"
        class="h-10 !w-full !px-6"
      />
      <div class="mt-2 grid grid-cols-2 gap-2 md:grid-cols-3">
        <ElementsButton
          v-for="item of links"
          :key="item.name"
          v-bind="item"
          type="block"
          class="flex h-10"
        >
          <span class="text-sm">{{ item.name }}</span>
        </ElementsButton>
        <ElementsButton
          v-for="item of items"
          v-bind="item"
          :key="item.name"
          type="block"
          class="h-10 !bg-[#F2F2F2] !text-gray-700"
          @click="() => (item.onClick ? item.onClick() : undefined)"
        >
          <span class="text-sm">{{ item.name }}</span>
        </ElementsButton>
      </div>
    </div>
    <div class="my-8">
      <ElementsCarInputPagination
        :id="car.id"
        :catalog-number="car.catalogNumber"
        :auction-id="car.auctionId"
        type="barometer"
      />
    </div>

    <DialogSupplier
      v-if="car.supplier"
      :supplier-label="car.supplier.label"
      :is-opened="depositorModalShown"
      :item-location-code="car.additionalInformation.itemLocationCode"
      :item-location-country-name="
        car.additionalInformation.itemLocationCountry?.name ?? ''
      "
      @close="depositorModalShown = false"
    />
  </div>
</template>

<script setup lang="ts">
import type { BarometerCar } from '@autobid/ui/types/Car'
import CurrentAuctionButton from '@autobid/ui/components/elements/car/CurrentAuctionButton.vue'
import type { AuctionState } from '@autobid/ui/types/AuctionState'
import type { AuctionItemProps } from '@autobid/ui/types/components/AuctionItem'
import { useAuction } from '@autobid/ui/composables/useAuction'
import { COLORS } from '@autobid/ui/utils/car/getColor'
import { computed } from 'vue'
import { useCarBarometerButtons } from '@autobid/ui/composables/car/useCarBarometerButtons'
import { useAutobidAuth } from '@autobid/nuxt-auth/src/composables/useAutobidAuth'
import DialogSupplier from '@autobid/ui/components/common/dialog/DialogSupplier.vue'
import BidMobileInfo from './BidMobileInfo.vue'
import BidActionMobile from './BidActionMobile.vue'
import CarMobileDescription from './CarMobileDescription.vue'
import HeaderMobile from './HeaderMobile.vue'

type Props = {
  car: BarometerCar
  timeRemaining: string
  title: string
  isCountdownOver: boolean
}
const props = defineProps<Props>()
const emits = defineEmits(['showAllBids'])

const state: AuctionState = inject('state')
const barometerType = inject('barometerType')

const { t } = useI18n()
const { auction } = useAuction(props.car.auctionId)
const { isAuthed } = useAutobidAuth()
const depositorModalShown = ref(false)

const { transportUrl, scaPopupSlug, taxPopupSlug }: AuctionItemProps =
  inject('auction-item-props')

const {
  expertOpinionBtnData,
  generalPDFBtnData,
  extraChargeBtn,
  externalUrlBtn
} = useCarBarometerButtons(computed(() => props.car))

const links = computed(() =>
  [
    expertOpinionBtnData.value,
    generalPDFBtnData.value,
    externalUrlBtn.value
  ].filter(Boolean)
)
const items = computed(() =>
  [
    transportUrl
      ? { name: t('barometer.vehicle-transport'), url: transportUrl }
      : null,
    {
      name: t('barometer.depositor-location'),
      onClick: () => {
        depositorModalShown.value = true
      }
    },
    extraChargeBtn.value,
    isAuthed.value
      ? {
          name: t('barometer.show-all-bids'),
          onClick: () => {
            emits('showAllBids')
          }
        }
      : null
  ].filter(Boolean)
)
</script>
